import axios from "../http-client/axios";
import { SCORPIONS_URL, METALICA_URL } from "../urls/base-urls";
import requestBuilder from "../request-builder/request-builder";
import FormRequestAction from "../../static-data/FormRequestAction";
import TypeRequestedFlow from "../../static-data/TypeRequestedFlow";
import mapperToContactRequest from "./contacts-mapper";
import ServiceRequestType from "../../static-data/ServiceRequestType";

export async function getContacts(crm) {
  const resp = await axios({
    url:  `${SCORPIONS_URL}/v2/contacts?codePerson=${crm}`,
    method: "get",
    data: {},
    headers: { "content-type": "application/json" },
  });

  return resp.data;
}

export async function jmjUpdateContact(formData) {
  var originalData = mapperToContactRequest(
    formData.originalData,
    FormRequestAction.update
  );

  var changedData = mapperToContactRequest(formData, FormRequestAction.update);

  const resp = await axios.post(
    `${METALICA_URL}/v2/integration-flow`,
    requestBuilder(
      originalData,
      TypeRequestedFlow.update,
      [],
      ServiceRequestType.PERSON_DATA_CONTACT,
      null,
      changedData
    )
  );
  return resp.data;
}
export async function updateContact(formData) {
  const bodyToRequest = {
    ...formData,
   "typeAddress": formData.addressId,
    "indContact": formData.addressId,
   "typeContact": formData.oldAssCod,
   "searchType": formData.originalData.oldAssCod,
  }

  const resp = await axios.put(`${SCORPIONS_URL}/contacts`, bodyToRequest);
  return resp.data;
}

export async function addContact(formData) {
  const resp = await axios.post(
    `${METALICA_URL}/v2/integration-flow`,
    requestBuilder(
      mapperToContactRequest(formData, FormRequestAction.insert),
      TypeRequestedFlow.save,
      [],
      ServiceRequestType.PERSON_DATA_CONTACT
    )
  );
  return resp.data;
}

export async function deleteContact(formData) {
  const resp = await axios.post(
    `${METALICA_URL}/v2/integration-flow`,
    requestBuilder(
      mapperToContactRequest(formData, FormRequestAction.delete),
      TypeRequestedFlow.delete,
      [],
      ServiceRequestType.PERSON_DATA_CONTACT
    )
  );
  return resp.data;
}

export async function getTypeContact() {
  const resp = await axios({
    url: `${SCORPIONS_URL}/types-contact`,
    method: "get",
    data: {},
    headers: {
      "content-type": "application/json",
    },
  });

  return resp.data;
}
