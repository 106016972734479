<template>
  <div>
    <b-card border-variant="light" class="custom-card my-4">
      <p
        class="P21"
        v-if="this.form.typeContact"
        v-text="this.form.typeContact"
      ></p>
      <p class="P21" v-if="!this.form.typeContact">NOVO CONTATO</p>
      <b-form id="alter-form" class="alter-form">
        <b-form-row>
          <b-col>
            Tipo
          </b-col>
          <b-col>
            <b-form-select
              v-model.lazy.trim="form.typeContact"
              :state="validateField('typeContact')"
              :options="contactTypesOptions"
            />
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col>
            Telefone
          </b-col>
          <b-col>
            <CustomMaskInput
              :initialValue="form.number"
              :options="numberOptions"
              placeholder="Digite"
            />
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col>
            Celular
          </b-col>
          <b-col>
            <CustomMaskInput
              :initialValue="form.numberCel"
              :options="numberOptionsCel"
              placeholder="Digite"
            />
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col>
            Email
          </b-col>
          <b-col>
            <b-form-input
              v-model.lazy.trim="form.email"
              type="email"
              :state="validateField('email')"
              placeholder="Digite"
            />
          </b-col>
        </b-form-row>
      </b-form>
    </b-card>

    <div class="my-3">
      <p class="p4 color-green-2" v-if="!formNotTouched()">
        As alterações que você está prestes a realizar, terão
        <strong>impacto direto no eSocial</strong>, assim como em seus
        <strong>dados do plano de saúde</strong>, ao salvar, algumas alterações
        acontecerão de forma automática em nossa base de dados, mas fique
        tranquilo(a),
        <strong
          >você poderá alterar essas informações novamente a qualquer
          momento</strong
        >.
      </p>
    </div>
    <div class="d-flex custom-check" v-if="!formNotTouched()">
      <b-form-checkbox size="lg" v-model="agree"
        ><p class="p4">
          Declaro que estou ciente das regras referentes a alteração de
          informações
        </p></b-form-checkbox
      >
    </div>

    <b-row class="my-5">
      <b-button
        variant="outline-primary"
        @click="this.onClosetHandler"
        class="ml-4"
      >
        Cancelar
      </b-button>

      <b-button
        form="alter-form"
        type="button"
        class="ml-4"
        variant="primary"
        id="submitButtonText"
        :disabled="!agree || this.$store.getters.isAdmin"
        @click="this.onSubmitHandler"
      >
        Salvar
      </b-button>
    </b-row>
  </div>
</template>

<script>
import { getTypeContact } from "../../../../../services/contacts/contactsService";

import CustomMaskInput from "../../../../../components/forms/custom-mask-input/CustomMaskInput.vue";

const InvalidEventHandler = () => {
  console.warn("Invalid onChange handler");
};

const OptionsNumber = {
  numericOnly: true,
  delimiters: ["(", ") ", " ", "-"],
  blocks: [0, 2, 9],
  onValueChanged: InvalidEventHandler,
};

const DefaultForm = {
  typeContact: null,
  typeAddress: null,
  codePerson: null,
  addressId: null,
  subjectContact: null,
  number: null,
  numberCel: null,
  email: null,
  originalData: null,
};

export default {
  props: [
    "loadedFormData",
    "newRegister",
    "onClose",
    "typeAddressesOptions",
    "onSubmit",
  ],
  components: { CustomMaskInput },
  mounted() {
    this.initForm();
    this.initTypeContact();
  },
  data() {
    return {
      formSubmited: false,
      form: {
        ...DefaultForm,
      },
      validations: {},
      contactTypesOptions: [],
      numberOptions: {
        ...OptionsNumber,
        onValueChanged: (e) => this.onChangeNumber(e, "number"),
      },
      numberOptionsCel: {
        ...OptionsNumber,
        onValueChanged: (e) => this.onChangeNumber(e, "numberCel"),
      },
      selectedOption: null,
      selectOptions: [],
      agree: false,
    };
  },
  methods: {
    formNotTouched() {
      return (
        this.form.typeContact == this.form.originalData?.typeContact &&
        this.form.number == this.form.originalData?.number &&
        this.form.numberCel == this.form.originalData?.numberCel &&
        this.form.email == this.form.originalData?.email
      );
    },
    initForm() {
      const loadedFormData = this.$props.loadedFormData;
      if (loadedFormData) {
        this.form = {
          ...this.form,
          ...loadedFormData,
          formSelected: false,
        };
        this.form.originalData = { ...this.form };
      }
    },
    async initTypeContact() {
      try {
        this.contactTypesOptions = this.buildSelectTypeContactOptions(
          await getTypeContact()
        );
        this.globalLoading = false;
      } catch (error) {
        this.globalLoading = false;
        console.error("getTypeContact", error);
      }
    },
    buildSelectTypeContactOptions(dataOptions) {
      return [
        { value: null, text: "Selecione um tipo de contato", disabled: true },
        ...dataOptions.map((formData) => ({
          value: formData.description,
          text: formData.description,
        })),
      ];
    },
    buildSelectOptions(formDataOptions) {
      return [
        { value: null, text: "Selecione um contato", disabled: true },
        ...formDataOptions.map((formData) => ({
          value: formData,
          text: formData.typeAddress + " - " + formData.typeContact,
        })),
      ];
    },
    getOnlyNumber(number) {
      try {
        if (number) return number.replace(/[^0-9]+/g, "");
      } catch (error) {
        console.error("Error on getNumber trying to parse.", error);
        return number;
      }
    },
    onChangeNumber(e, value) {
      this.form[value] = e.target.rawValue;
    },
    validateField(name) {
      let value = this.form[name];

      let validation = !!value;
      this.validations[name] = validation;
      if (!this.formSubmited) return null;

      return !!value;
    },
    requiredFieldsFilled() {
      return Object.values(this.validations).every(
        (fieldValue) => fieldValue == true
      );
    },
    onSubmitHandler() {
      this.formSubmited = true;
      if (this.requiredFieldsFilled()) {
        this.$props.onSubmit(this.form);
      }
    },
    onDelete() {
      return this.selectedOption;
    },
    onClosetHandler() {
      if (this.$props.onClose) {
        this.$props.onClose();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
div.form-row {
  padding: 0.5rem;
  border-bottom: 1px solid $gray-300;
  &:last-child {
    border-bottom: none;
  }
}

.P21 {
  color: var(--primary);
  font-size: multiplier-default-font-size(3); // 16
  margin-left: 1.5rem;
}
input.form-control {
  text-align: right;
  border: 1px solid transparent;
}

select.custom-select {
  text-align: right;
  border: 1px solid transparent;
}
.p4 {
  font-size: 1.6rem;
}
</style>
