import { formatFone } from "../../utils/format/formtUtils";

export default function mapperToContactRequest(formData, requestAction) {
  return {
    "Ação da solicitação": requestAction,
    "Tipo do Contato": formData.typeContact,
    "Número de Telefone": getFoneFormatado(formData.number),
    "Número de Celular": getFoneFormatado(formData.numberCel),
    "E-mail": formData.email,
  };
}

function getFoneFormatado(value) {
  
  if(value && value.indexOf('(') != -1)
    return value;

  const fone = formatFone(value);
  
  return fone === null || fone === undefined ? "" : fone;
}
