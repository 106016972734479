<template>
  <div>
    <b-row class="justify-content-center mb-4">
      <p class="p4 color-green-2">
        Confira seus dados e, caso haja divergências, solicite alteração
        cadastral a qualquer momento.
      </p>
    </b-row>

    <div v-show="!this.loading && !this.inEditing">
      <AccordionList
        titleKeyName="typeAddress;typeContact"
        :items="this.contacts"
        :itemsFields="this.tableFields"
        :onEdit="this.onEdit"
        :onDelete="this.onDelete"
        msgToggleEdit="Editar Contato"
        msgToggleDelete="Excluir Contato"
      />
    </div>
    <div v-show="this.loading">
      <b-card>
        <b-skeleton type="AccordionList" width="100%"></b-skeleton>
        <b-skeleton-table
          :rows="tableFields.length"
          :columns="2"
          :hide-header="true"
        ></b-skeleton-table>
      </b-card>
      <p />
      <b-card>
        <b-skeleton type="AccordionList" width="100%"></b-skeleton>
        <b-skeleton-table
          :rows="tableFields.length"
          :columns="2"
          :hide-header="true"
        ></b-skeleton-table>
      </b-card>
    </div>
    <div class="mt-5" v-show="!this.loading && this.inEditing">
      <NewContactsForm
        v-if="this.inEditing"
        :onSubmit="this.onSubmitEdit"
        :onClose="this.onClose"
        :loadedFormData="this.loadedFormData"
      />
    </div>

    <div class="mt-5" v-show="!this.loading && !this.inEditing">
      <b-button
        class="my-3 mr-3"
        variant="outline-primary"
        @click="this.onClickAddNewContact"
        v-b-modal:contacts-add-modal
      >
        Adicionar contato
      </b-button>
    </div>
    <success-modal
      ref="success-modal"
      :description="this.msgSuccess"
      buttonText="Ok, entendi!"
      :protocol="this.protocol"
    />
    <attention-modal
      modalId="delete-attention-modal"
      title="Atenção!"
      submitButtonText="Sim"
      cancelButtonText="Não"
      ref="attention-modal"
      :onAction="this.onDeleteComfirm"
    >
      <template slot="description">
        <p class="p1 mb-5">Tem certeza que deseja excluir este contato?</p>
      </template>
    </attention-modal>
  </div>
</template>

<script>
import AccordionList from "../../../../components/accordion-list/AccordionList.vue";
import {
  getContacts,
  deleteContact,
  updateContact,
  jmjUpdateContact,
  addContact,
} from "../../../../services/contacts/contactsService";
import { inject } from "@vue/composition-api";

import SuccessModal from "../../../../components/base-modals/SuccessModal.vue";
import AttentionModal from "../../../../components/base-modals/AttentionModal.vue";
const tableFields = [
  { key: "typeContact", label: "Tipo", tdClass: "text-right custom-table" },
  { key: "number", label: "Telefone", tdClass: "text-right" },
  { key: "numberCel", label: "Celular", tdClass: "text-right" },
  { key: "email", label: "E-mail", tdClass: "text-right" },
];
import NewContactsForm from "../forms/contacts/NewContactsForm.vue";

const ContactInformation = {
  name: "contact-information",
  components: {
    AccordionList,
    SuccessModal,
    AttentionModal,
    NewContactsForm,
  },

  setup() {
    const globalLoading = inject("globalLoading");
    return { tableFields, globalLoading };
  },
  mounted() {
    this.getContactsInfo();
  },
  data() {
    return {
      contacts: [],
      loading: true,
      protocol: "",
      inEditing: false,
      loadedFormData: null,
      msgSuccess: "",
    };
  },
  methods: {
    onClose() {
      this.inEditing = false;
    },
    onEdit(item) {
      this.loadedFormData = item;
      this.inEditing = true;
    },
    async onSubmitEdit(formData) {
      this.globalLoading = true;
      try {
        if (this.newRegister == true) {
          this.msgSuccess = "Novo contato cadastrado.";
          this.protocol = await addContact(formData);
        } else {
          this.msgSuccess = "Sua informações foram alteradas.";
          this.protocol = await jmjUpdateContact(formData);
          await updateContact(formData);
        }
        this.showSuccessModal();
        this.onClose();
        this.getContactsInfo();
        this.newRegister = false;
        this.globalLoading = false;
      } catch (err) {
        console.error(err);
        this.newRegister = false;
        this.globalLoading = false;
      }
    },

    async onDeleteComfirm(confirmed) {
      if (!confirmed) return;
      try {
        this.globalLoading = true;
        this.msgSuccess = "Contato excluído!";
        this.protocol = await deleteContact(this.itemToDelete);
        this.globalLoading = false;
        this.showSuccessModal();
      } catch (error) {
        this.globalLoading = false;
        console.error("deleteContact", error);
      }
    },
    async onDelete(item) {
      this.itemToDelete = item;
      const modal = this.$refs["attention-modal"].$children[0];
      modal.show();
    },
    showSuccessModal() {
      const modal = this.$refs["success-modal"].$children[0];
      modal.show();
    },
    async getContactsInfo() {
      try {
        const { codePerson } = this.$store.getters.getUserInformation;
        this.loading = true;
        this.contacts = await getContacts(codePerson);
        this.loading = false;
      } catch (error) {
        this.loading = false;
        console.error("getContactsInfo", error);
      }
    },
    async onClickAddNewContact() {
      this.newRegister = true;
      this.loadedFormData = {};
      this.inEditing = true;
    },
  },
};

export default ContactInformation;
</script>

<style lang="scss" scoped>
.addresses-container td::before {
  text-align: left !important;
}

.addresses-container td:first-child {
  border-color: #eff2f5;
}
</style>
